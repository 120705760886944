import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import ImageSlider from '../Components/Elements/Slider/ImageSlider'
import VideoSlider from '../Components/Elements/Slider/VideoSlider'

function Slider() {
  return (
    <Layout title='slider'>
    {/* <CompLayout title={"image slider"}>
<ImageSlider />
    </CompLayout> */}
    {/* <CompLayout title={"video slider"}>
<VideoSlider />
    </CompLayout> */}
    </Layout>
  )
}

export default Slider