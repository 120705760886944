import React, { useState } from 'react';
import style from './comp_layout.module.scss';
import { Card, Modal, Tabs, message } from 'antd';

function CompLayout({ children, title,code = {
  key: 1,
  comp: "Primary Button",
  code: [
    {
      tab: 'js',
      code: `//js error handler code`
    },
    {
      tab: 'scss',
      code: `//scss error handler code`
    },
  ]
}, }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('js'); 

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [messageApi, contextHolder] = message.useMessage();

  const copyIconClick = () => {
    const activeTabIndex = code.code.findIndex(item => item.tab === activeTab);
    if (activeTabIndex !== -1) {
      const codeToCopy = code.code[activeTabIndex].code;
      navigator.clipboard.writeText(codeToCopy)
        .then(() => {
          messageApi.open({
            type: 'success',
            content: 'Code copied',
            className: 'auf-message'
          });
        })
        .catch((error) => {
          console.error('Error copying code:', error);
          messageApi.open({
            type: 'error',
            content: 'Error copying code. Please try again.',
            className: 'auf-message'
          });
        });
    }
  };
  

  return (
    <>
      {contextHolder}
      <div className={style["comp-wrapper"]}>
        <div className={style["title-container"]}>
          <h2 className={style["comp-title"]}>{title}</h2>
          <div className={style["action-container"]} onClick={showModal}>
            <span className={style["code-icon"]}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 18L22 12L16 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 6L2 12L8 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
            <h6 className={style["code-title"]}>Code</h6>
          </div>
        </div>
        <div className={style["children-container"]}>
          {children}
        </div>
      </div>
      <Modal className='auf-modal' title={title} footer={false} width={1000} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Tabs
          tabBarExtraContent={
            <span onClick={copyIconClick} className={style["copy-icon"]}>
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
          }
          className='auf-tabs' defaultActiveKey="1"
          onChange={(key) => setActiveTab(code.code[key - 1].tab)}
        >
          {code.code.map((item, index) => (
            <Tabs.TabPane key={index + 1} tab={item.tab}>
              <Card className='auf-card' title={false}>
                <pre key={item.tab}>
                  <code>{item.code}</code>
                </pre>
              </Card>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Modal>
    </>
  );
}

export default CompLayout;
