import { createContext, useState } from "react";
export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [theme,setTheme] = useState('light')
  const themeChanger = (key) =>{
      switch (key){
          case "1":
              setTheme('light')
              break;
          case "2":
              setTheme('dark')
              break;
          default:
                  console.log('theme is not available');
                  break;
      }
  }
    const value = {
      theme,themeChanger
    };
  
    return (
      <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    );
  };