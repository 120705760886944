import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Button from "./pages/Button";
import Toggle from "./pages/Toggle";
import Tooltip from "./pages/Tooltip";
import Checkbox from "./pages/Checkbox";
import Collapse from "./pages/Collapse";
import RadioButton from "./pages/RadioButton";
import Upload from "./pages/Upload";
import Popup from './pages/Popup';
import DatePicker from "./pages/DatePicker";
import Table from "./pages/Table";
import TimePicker from "./pages/TimePicker";
import Tab from "./pages/Tab";
import Card from "./pages/Card";
import Drawer from "./pages/Drawer";
import Slider from "./pages/Slider";
import SearchBox from "./pages/SearchBox";
import FormField from "./pages/FormField";
import Breadcrumb from "./pages/Breadcrumb";
import Tag from "./pages/Tag";
import Rate from "./pages/Rate";

const AppRouter = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Button />} />
        <Route path="/toggle" element={<Toggle />} />
        <Route path="/tooltip" element={<Tooltip />} />
        <Route path="/checkbox" element={<Checkbox />} />
        <Route path="/collapse" element={<Collapse />} />
        <Route path="/radio" element={<RadioButton />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/date" element={<DatePicker />} />
        <Route path="/table" element={<Table />} />
        <Route path="/time" element={<TimePicker />} />
        <Route path="/tab" element={<Tab />} />
        <Route path="/card" element={<Card />} />
        <Route path="/drawer" element={<Drawer />} />
        <Route path="/slider" element={<Slider />} /> 
        <Route path="/search" element={<SearchBox />} /> 
        <Route path="/form" element={<FormField />} /> 
        <Route path="/breadcrumb" element={<Breadcrumb />} /> 
        <Route path="/tag" element={<Tag />} /> 
        <Route path="/rate" element={<Rate />} /> 
      </Routes>
    </Router>
  );
};

export default AppRouter;
