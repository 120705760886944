import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicTab from '../Components/Elements/Tab/BasicTab'
import SlideTab from '../Components/Elements/Tab/SlideTab'

function Tab() {
  return (
    <Layout title='tab'>
    {/* <CompLayout title={"Basic tab"}>
<BasicTab />
    </CompLayout> */}
    {/* <CompLayout title={"Slide tab"}>
<SlideTab />
    </CompLayout> */}
    </Layout>
  )
}

export default Tab